import XSockets from '../XSockets.latest';
import reduxStore from '../../Redux/store';
import Logger from '../Logging';

const INFO = 'GnuralUI/websockets';
const GSocket = {};

/*
 * Event Format:
 * ['WebSocket Event Name', 'Redux Success Name', 'Redux Failure Name']
 * Note: Failure Name is not Essential
 */
GSocket.Events = [
	['login', 'LOGIN_FULFILLED', 'LOGIN_REJECTED'],
	['contextcreated', 'ON_CONTEXT_CREATED_FULFILLED', 'ON_CONTEXT_CREATED_REJECTED'],
	['getshows', 'GET_SHOWS_FULFILLED', 'GET_SHOWS_REJECTED'],
	['getshowguests', 'GET_GUESTS_FULFILLED', 'GET_GUESTS_REJECTED'],
	['updateshowguest', 'ON_UPDATE_SHOW_GUEST', 'ON_UPDATE_SHOW_GUEST_REJECTED'],
	['newshowguest', 'ON_NEW_SHOW_GUEST', 'ON_NEW_SHOW_GUEST_REJECTED'],
	//['getshowguesthistory', 'GET_SHOW_GUEST_HISTORY_FULFILLED', 'GET_SHOW_GUEST_HISTORY_REJECTED'],
	['closeshow', 'CLOSE_SHOW_FULFILLED', 'CLOSE_SHOW_REJECTED'],
	['createshow', 'ON_CREATE_SHOW_FULFILLED', 'ON_CREATE_SHOW_REJECTED'],
	['flushshow', 'ON_FLUSH_SHOW_FULFILLED', 'ON_FLUSH_SHOW_REJECTED'],
	['getltausers', 'ON_GET_LTA_USERS_FULFILLED', 'ON_GET_LTA_USERS_REJECTED'],
	['connecttoshowguest', 'ON_CONNECT_TO_SHOW_GUEST_FULFILLED', 'ON_CONNECT_TO_SHOW_GUEST_REJECTED'],
	['remotestream', 'ON_REMOTE_STREAM'],
	['disconnectshowguest', 'DISCONNECT_SHOW_GUEST_FULFILLED', 'DISCONNECT_SHOW_GUEST_REJECTED'],
	['disconnectpeer', 'DISCONNECT_PEER_FULFILLED', 'DISCONNECT_PEER_REJECTED'],
	['BrowserInfoResponce', 'REQUEST_BROWSER_INFO_FULFILLED', 'REQUEST_BROWSER_INFO_REJECTED'],
	['lockshowguest', 'LOCK_SHOW_GUEST_FULFILLED', 'LOCK_SHOW_GUEST_FAILED'],
	['peerconnectionlost', 'DISCONNECT_PEER_REMOTE'],
	['entershow', 'ON_ENTER_SHOW'],
	['getrelayservers', 'GET_RELAY_SERVERS_FULFILLED', 'GET_RELAY_SERVERS_REJECTED'],
	['objecttopeer', 'ON_OBJECT_TO_PEER_FULFILLED', 'ON_OBJECT_TO_PEER_REJECTED']
];

GSocket.host = (() => {
	const state = reduxStore.getState();
	return state.websocket.Hostname;
})();

GSocket.startTimeout = (time=20000) => {
	if (GSocket.timeout) {
		clearTimeout(GSocket.timeout);
	}
	GSocket.timeout = setTimeout(() => {
		Logger.error(INFO, 'Websocket Heartbeat Failed');
		if (GSocket.websocket) {
			GSocket.websocket.close();
		}
	}, time);
};

GSocket.resetTimeout = () => {
	GSocket.startTimeout();
};

GSocket.uri = 'wss://' + GSocket.host + ':4502/GSignal';

const reopenCallbackList = [];

GSocket.onReopen = (callback) => {
	reopenCallbackList.push(callback);
}

GSocket.openWebsocket = () => {

	reduxStore.dispatch({type: 'WEBSOCKET_CONNECTION_PENDING'});
	GSocket.websocket = new XSockets.WebSocket(GSocket.uri, (e) => {
		Logger.error(INFO, 'Failed to open websocket: ', e);
		reduxStore.dispatch({type: 'WEBSOCKET_CONNECTION_REJECTED'});
	});

	GSocket.websocket.on(XSockets.Events.open, (brokerClient) => {
		Logger.log(INFO, 'Websocket Started: ', brokerClient);
		if (!brokerClient || !brokerClient.ClientGuid || !brokerClient.StorageGuid) {
			Logger.error(INFO, 'Could not Get BrokerClient ClientGuid or StorageGuid');
			return;
		}
		GSocket.websocket.subscribe('heartbeat', GSocket.resetTimeout);
		GSocket.startTimeout();


		reduxStore.dispatch({type: 'WEBSOCKET_CONNECTION_FULFILLED'});
		reduxStore.dispatch({type: 'UPDATE_USER_INFO', payload: brokerClient});
		const storeState = reduxStore.getState();
		if (storeState && storeState.user && storeState.user.Username && storeState.user.Password) {
			GSocket.websocket.publish('login', {
				UserName: storeState.user.Username,
				UserPass: storeState.user.Password
			});
			reduxStore.dispatch({type: 'LOGIN_PENDING'});
		}


		GSocket.Events.forEach( (event) => {
			GSocket.websocket.subscribe(event[0], (payload) => {
				if ((!payload || (payload && payload.Error)) && (event.length >= 3)) {
					reduxStore.dispatch({type: event[2], payload});
				} else {
					reduxStore.dispatch({type: event[1], payload});
				}
			});
		});
		reduxStore.InjectWebsocket(GSocket);


	});

	GSocket.websocket.onclose = (message) => {
		Logger.log(INFO, 'Websocket Closed:', message);
		reduxStore.dispatch({type: 'WEBSOCKET_CONNECTION_CLOSED'});
		GSocket.openWebsocket();
	};
	
	reopenCallbackList.forEach(callback => {
		if (callback && typeof(callback) === 'function') {
			callback(GSocket);
		}
	});
};

GSocket.openWebsocket();

export default GSocket;
import GSocket from '../../Utilities/Gnural/websockets';

export function getShowGuests(ShowId) {
	return (dispatch) => {
		GSocket.websocket.publish('getshowguests', { ShowId });
		dispatch({type: 'GET_SHOW_GUESTS_PENDING'});
	};
}

export function setSelectedGuest(guest) {
	return  (dispatch) => {
		dispatch({
			type: 'SET_SELECTED_GUEST',
			payload: guest
		});
	};
}

export function setGuestDevice(PeerGuid, UserGuid, Device, Value) {
	return (dispatch) => {
		if (!PeerGuid || !UserGuid || !Device || !Value) {
			dispatch({
				type: 'SET_GUEST_DEVICE_REJECTED',
				payload: {error: 'Invalid Parameters for Settings Guest Device'}
			});
			return;
		}
		GSocket.websocket.publish('setguestdevice', {PeerGuid, ReqGuid: UserGuid, Device, Value});
		GSocket.websocket.publish('requestbrowserinfo', {PeerGuid, ReqGuid: UserGuid});
		dispatch({
			type: 'SET_GUEST_DEIVCE_SENT'
		});
	};
}

export function reconnectPeer(Context, Recipient, Sender) {
	return {
		type: 'RECONNECT_PEER_PENDING',
		payload: {
			Context,
			Recipient,
			Sender
		}
	};
}

export function getShowGuestHistory() {
	return () => {

	};
	/*return (dispatch) => {
		GSocket.websocket.publish('getshowguesthistory', guest);
		dispatch({type: 'GET_SHOW_GUEST_HISTORY_PENDING'});
	};*/
}

export function getShowGuestDevices(guest, ClientGuid) {
	return (dispatch) => {
		if (guest && ClientGuid) {
			GSocket.websocket.publish('requestbrowserinfo', {PeerGuid: guest.CookieGuid, ReqGuid: ClientGuid});
			dispatch({
				type: 'ON_REQUEST_BROWSER_INFO_PENDING'
			});
		}
	};
}

export function moveGuestToQueue(guest, NewQueue, ReqId) {
	return {type: 'UPDATE_SHOW_GUEST_PENDING', payload: {guest: {...guest, ReqId}, NewQueue}};
}

/*export function disconnectShowGuest(peerId) {
	return (dispatch) => {
		if (!peerId) {
			dispatch({type: 'DISCONNECT_PEER_REJECTED', payload: {Error: 'No Guest to Disconnect'}});
			return;
		}
		try {
			GnuralRTC.removePeerConnection(peerId, null);
		} catch (e) {
			Logger.error(INFO, 'Failed to Remove Peer Connection: ', peerId);
		}
		GSocket.websocket.publish('disconnectpeer', {recipient: peerId});
		GSocket.websocket.publish('leavecontext', {});
		RemoteMediaHolder.setRemoteStream(undefined);
		dispatch({type: 'DISCONNECT_PEER'});
	};

}*/
export function disconnectShowGuest(guest, NewQueue, ReqId) {
	return {type: 'DISCONNECT_PEER_PENDING', payload: {guest: {...guest, ReqId}, NewQueue}};
}

export function updateGuest(guest, ReqId) {
	return (dispatch) => {
		GSocket.websocket.publish('updateshowguest', {...guest, ReqId});
		dispatch({type: 'UPDATE_SHOW_GUEST_PENDING'});
	};
}

/*export function connectToShowGuest(guest) {
	return (dispatch) => {
		GSocket.websocket.publish('updateshowguest', {...guest, CallState: 'Screening'});
		GSocket.websocket.publish('connecttoshowguest', guest);
		dispatch({type: 'ON_CONNECT_TO_SHOW_GUEST_PENDING', payload: guest});
	};
}*/
export function connectToShowGuest(guest, ReqId) {
	return {type: 'ON_CONNECT_TO_SHOW_GUEST_PENDING', payload: {...guest, ReqId}};
}

/*export function getShowGuestLock(guest) {
	return (dispatch) => {
		GSocket.websocket.publish('lockshowguest', guest);
		dispatch({type: 'LOCK_SHOW_GUEST_PENDING', payload: guest});
	};
}*/

export function getShowGuestLock(guest) {
	return ({
		type: 'LOCK_SHOW_GUEST_PENDING',
		payload: guest
	});
}

export function manuallyUndoGuestLock(guest, ReqId) {
	return (dispatch) => {
		GSocket.websocket.publish('updateshowguest', {...guest, CallState: 'Incoming', ReqId});
		dispatch({type: 'LOCK_SHOW_GUEST_REMOVE'});
	};
}

export function SendObjectToShowGuest(guest, MessageObject) {
	return (dispatch) => {
		GSocket.websocket.publish('ObjectToShowGuest', {RecipientId: guest.CookieGuid, MessageObject});
		dispatch({
			type: 'SEND_OBJECT_TO_SHOW_GUEST_PENDING',
			payload: {
				RecipientId: guest.CookieGuid,
				MessageObject
			}
		});
	}
}

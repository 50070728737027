function websocket(state = {ConnectionStatus: 'CLOSED', Hostname: 'signal.blueprint.callin.cloud'}, action) {
	switch(action.type) {
	// Websocket Connection Status
	case 'WEBSOCKET_CONNECTION_PENDING': {
		return {...state, ConnectionStatus: 'PENDING'};
	}
	case 'WEBSOCKET_CONNECTION_FULFILLED': {
		return {...state, ConnectionStatus: 'CONNECTED'};
	}
	case 'WEBSOCKET_CONNECTION_REJECTED': {
		return {...state, ConnectionStatus: 'FAILED'};
	}
	case 'WEBSOCKET_CONNECTION_CLOSED': {
		return {...state, ConnectionStatus: 'CLOSED'};
	}
	default:
		return state;
	}
}

export default websocket;